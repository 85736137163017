<template>
  <div v-if="stowage">

    <b-card-text>Tipo de producto: {{ stowage.product_type.name }}</b-card-text>
    <b-row>
      <b-col md="4">
        <h4 class="text-primary">Estiba #{{ stowage.stowage_number }}</h4>
        <h4>Productos en la estiba: {{ totalProducts }}</h4>
        <h5 v-if="!stowage.have_sim" class="text-danger">Estos productos van sin SIM</h5>
      </b-col>

      <b-col md="4">
        <h4 v-if="box"
          class="text-primary">Caja #{{ box.box_number }}</h4>
        <h4>Cajas en la estiba: {{ stowage.boxes_count }} / {{ stowage.boxes_by_stowage }}</h4>
      </b-col>

      <b-col v-if="box" md="4">
        <h4 class="text-primary">Productos en la caja:</h4>
        <h4>{{ box.products_enlistment_count || 0 }} / {{ box.products_by_box }}</h4>
      </b-col>
      <b-col v-if="!stowageFinish" md="12">
        <b-button
          variant="danger"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-2 float-right"
          @click="validateCloseStowageModal = true"
        >
          Cerrar estiba
        </b-button>
      </b-col>
    </b-row>

    <hr>
    <!-- User Info: Input Fields -->
    <validation-observer ref="productEnlistmentValidation" v-if="!stowageFinish">

      <b-form
        @submit.prevent="saveProductEarlyEnlistment">
        <b-row>

          <!-- Field: Username -->
          <b-col
            cols="12"
            md="4"
          >

            <b-form-group
                label="Serial *"
                label-for="serial"
              >
              <validation-provider
                  #default="{ errors }"
                  name="Serial"
                  rules="required"
                >

                <b-form-input
                  id="serial"
                  v-model="serial"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>

          <b-col
            cols="12"
            md="4"
            v-if="stowage.product_type_id != 1 && stowage.have_sim"
          >

            <b-form-group
                label="SIM *"
                label-for="sim"
              >
              <validation-provider
                  #default="{ errors }"
                  name="SIM"
                  rules="required"
                >

                <b-form-input
                  id="sim"
                  v-model="sim"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>
          
        </b-row>

        <!-- Action Buttons -->
        <b-spinner
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          v-show="loading"></b-spinner>

        <b-button
          v-show="!loading"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Guardar
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="$router.push({name: 'early-enlistment'})"
        >
          Cancelar
        </b-button>
      
      </b-form>
    </validation-observer>

    <div v-else>

      <!-- <h6>Estado: {{ order.order_status.name }}</h6>
      <h6>Descripción: {{ order.order_status.description }}</h6>
      <h6>Cantidad de seriales: {{ order.quantity_products }}</h6> -->

      <h6>Se ha completado la lectura de todos los seriales de la estiba</h6>

      <b-button
        variant="primary"
        @click="$router.push({name: 'stowage-detail', params: { stowage_id:stowage.id } })"
        class="mt-2"
      >
        Ver estiba
      </b-button>


      <b-button
        variant="outline-secondary"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="$router.push({name: 'early-enlistment'})"
        class="mt-2  ml-1"
      >
        Ver alistamientos
      </b-button>

    </div>

    <b-modal
      v-model="validateCloseStowageModal"
      title="Cerrar estiba"
      ok-title="Cerrar estiba"
      ok-variant="danger"
      cancel-title="Cancelar"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="closeStowage"
    >

      <h4>¿Estás seguro de cerrar la estiba en este instante?</h4>
      <b-card-text>
        Vas a cerrar la estiba hasta este punto, ten en cuenta que este proceso no se puede revertir. Al cerrar la estiba quedará disponible para despachar en un pedido.
      </b-card-text>
    
    </b-modal>
    
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BCardText, BModal, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {constants} from "@/helpers/constants"

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardText,
    BModal,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      serial: '',
      sim: '',
      required,
      stowage: null,
      stowageFinish: false,
      box: null,
      totalProducts: null,
      validateCloseStowageModal: false,
      loading: false,
    }
  },
  props: {
    stowageId: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const countryOptions = [
      { label: 'Colombia', value: 1 },
    ]

    return {
      countryOptions,
    }
  },
  created() {
    this.getStowage()
  },
  methods: {
    getStowage() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-stowage-enlistment/' + this.stowageId, { headers }).then(response => {
        console.log('get-stowage-enlistment: ', response)

        this.stowage = response.data.data.stowage
        this.stowageFinish = response.data.data.stowage_finish
        this.box = response.data.data.box
        this.totalProducts = response.data.data.total_products
        
      }).catch( err => {
        console.log(err)
      })
    },
    saveProductEarlyEnlistment() {
      event.preventDefault()

      console.log(this.stowage.have_sim, this.sim.length, !this.stowage.have_sim || this.sim.length >= 17, this.stowage.product_type_id)

      if((!this.stowage.have_sim || this.sim.length >= 17) || this.stowage.product_type_id == 1) {

        this.$refs.productEnlistmentValidation.validate().then(success => {
          if (success) {

            this.loading = true

            let headers = {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + this.accessToken
            }
            axios.post(this.host + '/api/front-web/save-product-early-enlistment',{
              serial: this.serial,
              sim_serial: this.sim,
              box_id: this.box.id,
              product_type_id: this.stowage.product_type_id,
            }, { headers }).then(response => {
              console.log('save-product-early-enlistment: ', response)
              this.loading = false

              if(response.data.status) {
                this.serial = ''
                this.sim = ''
                this.$toast({
                  component: ToastificationContentVue,
                  position: 'top-right',
                  props: {
                    title: `OK`,
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: response.data.message,
                  },
                })
                this.getStowage()
                /* if(response.data.data.order_complete || response.data.data.finish_order) {
                  this.getOrder()
                } */
              } else {
                this.$toast({
                  component: ToastificationContentVue,
                  position: 'top-right',
                  props: {
                    title: `Advertencia`,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                    text: response.data.message,
                  },
                })
              }
            }).catch( err => {
              console.log(err)
            })

          }
        })
        
      } else {

        this.$toast({
          component: ToastificationContentVue,
          position: 'top-right',
          props: {
            title: `Advertencia`,
            icon: 'XCircleIcon',
            variant: 'warning',
            text: 'El serial de la sim card debe ser mayor o igual de 17 caracteres, revisa por favor y vuelve a intentarlo',
          },
        })

      }

    },
    closeStowage() {

      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.post(this.host + '/api/front-web/close-stowage', {
        stowage_id: this.stowage.id,
      }, { headers }).then(response => {
        console.log('close-stowage: ', response)

        if(response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            position: 'top-right',
            props: {
              title: `OK`,
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.getStowage()
        } else {
          this.$toast({
            component: ToastificationContentVue,
            position: 'top-right',
            props: {
              title: `Advertencia`,
              icon: 'XCircleIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
        }
      }).catch( err => {
        console.log(err)
      })

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
